<template>

<div class="construction">
    <Svg icon="construction" className="construction--svg"/>
     Nous revenons bientôt avec un nouveau site web.
</div>

</template>

<script>

import Svg from '@/components/utils/Svg';


export default {
    components:{
        Svg
    }
}
</script>

<style lang="scss">
@import '@/style/main';

.construction{
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &--svg{
        @include svg(15rem);
        margin-bottom: 2rem;
    }
}

</style>