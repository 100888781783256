import $ from "jquery";

export default function scrollTo(target, offset = 0) {
  console.log("in");
  const offsetNav = $("#navigation").height(); //$("#navigation").height();
  $("html, body").animate(
    {
      scrollTop: $("#" + target).offset().top - offsetNav - offset,
    },
    800
  );
}
