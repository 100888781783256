import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/:section?",
    name: "Home",
    component: Home,
    meta: {
      title: "Nous vous aidons à financer votre entreprise",
      metaTags: [
        {
          name: "description",
          content:
            "Le creditoring est une démarche qui vise à obtenir du financement pour votre entreprise aux meilleures conditions du marché.",
        },
      ],
    },
  },
  {
    path: "/lecreditoring",
    name: "creditoring",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "creditoring" */ "../views/Creditoring.vue"),
    meta: {
      title: "La démarche créditoring",
      metaTags: [
        {
          name: "description",
          content:
            "Trouver de l'argent pour ses projets d'entreprise n'est pas toujours chose facile. La démarche creditoring vous aide à optimiser vos recherches de liquidité.",
        },
      ],
    },
  },
  {
    path: "/risques",
    name: "Risque",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "risk" */ "../views/Risque.vue"),
    meta: {
      title: "Gestion des Risques",
      metaTags: [
        {
          name: "description",
          content:
            "Analyse de l'ensemble des évènements critiques afin d'assurer la continuité de votre activité.",
        },
      ],
    },
  },
  {
    path: "/article/:name",
    name: "article",
    component: () =>
      import(/* webpackChunkName: "article" */ "../views/Article.vue"),
    meta: {
      title: "Article sur le creditoring",
      metaTags: [
        {
          name: "description",
          content: "azeaz",
        },
      ],
    },
  },
];

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle)
    document.title = `${process.env.VUE_APP_TITLE} - ${nearestWithTitle.meta.title}`;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(
    document.querySelectorAll("[data-vue-router-controlled]")
  ).map((el) => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  // ROUTE PROTECTION

  next();
});

export default router;
