<template>
    <section class="section" :class="background ? `section--${background}` : ``">
        <div class="container section__container">
            <div class="section__title">
                <h1 v-if="titleh1">
                    {{titleh1}}
                </h1>
                <h2 v-if="title">
                    {{title}}
                </h2>
                <template v-if="subtitle">
                    <h3 v-html="subtitle"></h3>
                </template>
            </div>
            
            
            <slot/>
        </div>
    </section>
</template>

<script>
export default {
    props:['background', 'title', 'titleh1', 'subtitle'],
    setup(){

    }

}
</script>

<style lang="scss">
@import '@/style/main';

.section{
    box-shadow: $shadow;

    &__container{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 8rem 0 !important;

        @include respond(tab-land){
            padding: 8rem 2rem !important;
        }

        @include respond(phone){
            padding: 4rem 2rem !important;
        }
    }

    &__title{
        margin-bottom: 5rem;
         display: flex;
        flex-direction: column;
        align-items: center;

        @include respond(phone){
            margin-bottom: 2rem;
        }

        & h1, h2{
            color:$color-primary;
            text-transform: uppercase;
            font-weight: 900;
            font-size: 3.4rem;
            letter-spacing: .2rem;
            margin-bottom: 2rem;
            text-align: center;

            @include respond(phone){
                margin-bottom: 1rem;
                font-size: 2.8rem;
            }
        }

        & h3{
            color: $color-text;
            font-size: 2.5rem;
            text-align: center;

            & strong{
                @include strong;
            }

            @include respond(phone){
                font-size: 1.8rem;
            }
        }
        
    }

    &--red{
        background-color: $color-red;
    }
    &--blue{
        background-color: $color-blue-light;
    }

    

    

    
}

</style>