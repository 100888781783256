<template>
  <div class="footer">
      <div class="container footer__content">
          <div class="footer__heading">
              <strong>Se financer ? Pas si difficile que ça !</strong>
              <h3>Si l'on s'y prend bien ... et à temps !</h3>
          </div>

          <div class="footer__infos">
              <!-- <a href="tel:0032478496816" class="footer__infos--item" role="button">
                  <Svg icon="phone" className="footer__infos--svg"></Svg>
                  +32 478 49 68 16
              </a> -->
              <a href="mailto:info@creditoring.com" class="footer__infos--item" role="button">
                  <Svg icon="email" className="footer__infos--svg"></Svg>
                  info@creditoring.com
              </a>
              <div class="footer__infos--item">
                  <Svg icon="lieu" className="footer__infos--svg"></Svg>
                  <div>
                      30 Bd Général Jacques BP9 <br>
                        1050 Bruxelles
                  </div>
                  
              </div>
          </div>

          <div class="footer__bottom">
              <div class="footer__bottom--item">
                  © creditoring services srl
              </div>
              <div class="footer__bottom--item">
                  TVA: BE 0808.170.445
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import Svg from '@/components/utils/Svg';

export default {

    components:{
        Svg
    }

}
</script>

<style lang="scss">
@import '@/style/main';

.footer{
    background-color: $color-primary;
    color:$color-white;
    padding: 2rem 0rem  1rem 0rem!important;   
    
    @include respond(phone){
        padding: 1rem  0rem!important;
    }
    
    

    &__heading{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 2rem;

        font-size: 1.8rem;
        font-weight: 300;

        @include respond(phone){
            font-size: 2rem;
            margin-bottom: .5rem;
        }
        & strong{
            font-size: inherit;
            font-weight: 700;
            margin-bottom: .3rem;
            text-align: center;
            font-size: 2.1rem;
            
        }

        & h3{
            font-size: inherit;
            font-weight: 300;
            font-style: italic;
        }
    }

    &__content{
        display: flex;
        flex-direction: column;
        align-items: center;
        @include respond(phone){
            padding-top: 1rem !important;
            padding-bottom: 1rem !important;
        }
            
    }

    &__infos{
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 1rem 0;

        @include respond(phone){
            flex-direction: column;
            padding: 2rem 0;
        }

        &--item{
            @include buttonNoStyle;
            display: flex;
            align-items: center;

            @include respond(phone){
                &:not(:last-child){
                    margin-bottom: 1rem;
                }
            }

        }

        &--svg{
            margin-right: 1rem;
            @include svg(1.8rem);
        }
    }

    &__bottom{
        width: 100%;
        border-top: 1px solid currentColor;
        display: flex;
        justify-content: space-between;
        padding-top: 1rem;

        @include respond(phone){
            flex-direction: column;
        }

        &--item{
            text-transform: uppercase;
            @include respond(phone){
                &:not(:last-child){
                    margin-bottom: 1rem;
                }
            }   
        }
    }
}
</style>