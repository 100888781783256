<template>

    <header id="navigation" class="head__supercontainer">
        <div class="head__top">
            <div class="container head__top--content">
                <div class="head__top--phrase  u-hide-tab-port ">
                    La liquidité à la porté de votre PME !
                </div>
                <ul>
                    <li>
                         <a class="head__infos--item" href="mailto:info@creditoring.com">
                        <Svg className="head__infos--svg" icon="email"></Svg> 
                        info@creditoring.com
                    </a>
                    </li>
                    <!-- <li>                    
                        <a class="head__infos--item" href="tel:0032478496816">
                            <Svg className="head__infos--svg" icon="phone"></Svg> 
                            +32 478 49 68 16
                        </a>
                    </li> -->
                </ul>
            </div>
        </div>
        <div class="head">
             <div class="container head__container">
                <router-link to="/">
                    <img :src="require(`@/assets/logo.png`)" width="464" height="221" alt="logo creditoring services">
                </router-link>
                <div class="head__infos">
                    <ul>
                        <li v-for="s in menuScreen" :key="s.target" :class="s.hideTab? 'u-hide-tab' : s.hideTabPort? 'u-hide-tab-port':'' ">
                            <router-link :to="s.section ? {name:s.target, params: {section:s.section}}: {name:s.target}" class="side__btn" @click="displaySide=false">
                                <b>{{s.text}}</b>
                            </router-link>
                        </li>
                    </ul>
                    
                    <div class="head__infos--item u-hide-desktop">
                        <button class="head__menu" @click="toggleSide" aria-label="open side menu">
                            <div class="head__menu--icon">
                                <div class="head__menu--bar">&nbsp;</div>
                            </div>
                            Menu
                        </button>
                    </div>
                    
                </div>
            </div>
            <div  class="side__container" :class="{'side__container--hide': !displaySide, 'side__container--show': displaySide}">
                <button class="side__quit" aria-label="close side menu" @click="hideSide">
                    <Svg icon="delete" className="side__quit--svg"></Svg>
                </button>
                <div class="side__logo">
                    <img src="@/assets/logo-blanc.png" width="300" height="124" alt="logo creditoring blanc">
                </div>
                
                <div class="side">
                    <h2>Menu</h2>
                    <ul>
                        <li v-for="s in menuSide" :key="s.target">
                            <router-link :to="s.section ? {name:s.target, params: {section:s.section}}: {name:s.target}" class="side__btn" @click="displaySide=false">
                                {{s.text}}
                            </router-link>
                        </li>
                        <li v-for="s in menuSub" :key="s.target" class="side__sub">
                            <router-link :to="s.section ? {name:s.target, params: {section:s.section}}: {name:s.target}" class="side__btn" @click="displaySide=false">
                                {{s.text}}
                            </router-link>
                        </li>
                    </ul>
                </div>
                <a href="mailto:info@creditoring.com" class="side__contact">
                    <Svg className="side__contact--svg" icon="email"></Svg> info@creditoring.com
                </a>
                 <a href="tel:0032478496816" class="side__contact">
                    <Svg className="side__contact--svg" icon="phone"></Svg> +32 478 49 68 16 
                </a>
            </div>
        </div>
       
    </header>
  
</template>

<script>
import Svg from '@/components/utils/Svg';
import { ref } from '@vue/reactivity';

export default {
    components:{
        Svg
    },
    setup(){
        const displaySide = ref(false);

        const hideSide = () => {
            displaySide.value = false;
        }
        const toggleSide = () => {
            displaySide.value = !displaySide.value;
        }

        const menuScreen = [
            {
                text:"Creditoring",
                target:"Home",
                hideTabPort:true,
            },
            // {
            //     text:"Démarche",
            //     target:"Home",
            //     hideTab:true,
            //     "section": "demarche"
            // },
             {
                text:" Démarche",
                "target": "Home",
                "section": "mission"
            },
             {
                "text": "Risques",
                "target": "Risque"
            },
             {
                text:" Compétences",
                "target": "Home",
                "section": "competences"
            },
           
            //  {
            //     text: "Contact",
            //     "target": "Home",
            //     "section": "profil"
            // },
            {
                text: "témoignages",
                "target": "Home",
                "section": "temoignages",
                hideTab:true
            },
             {
                "text": "Principes",
                "target": "creditoring"
            },
            
        ]

        const menuSide = [
             {
                "text": "Creditoring",
                "target": "Home"
            },
            {
                text: "Démarche",
                "target": "Home",
                "section": "mission"
            },
              {
                "text": "Risques",
                "target": "Risque"
            },
             {
                text:" Compétences",
                "target": "Home",
                "section": "competences"
            },
            {
                text: "Contact",
                "target": "Home",
                "section": "profil"
            },
            {
                text: "Témoignages",
                "target": "Home",
                "section": "temoignages"
            },
            {
                "text": "Principes",
                "target": "creditoring"
            },
        ];
        const menuSub = [
            {
                "text": "Votre contexte",
                "target": "creditoring",
                "section": "contexte"
            },
            {
                "text": "Les effets du creditoring",
                "target": "creditoring",
                "section": "effects"
            },
            {
                text: "5 vérités",
                "target": "creditoring",
                "section": "verites"
            },
            {
                text: "Les instruments",
                "target": "creditoring",
                "section": "instruments"
            },
            {
                text: "Les financeurs",
                "target": "creditoring",
                "section": "financiers"
            },
             {
                text: "Nos articles",
                "target": "creditoring",
                "section": "articles"
            },
        ]
        
        return {
            displaySide,
            hideSide,
            menuScreen,
            menuSide,
            menuSub,
            toggleSide
        }
    }

}
</script>

<style lang="scss">
@import '@/style/main';


.head{
    display: flex;
    border-bottom: 2px solid $color-primary;
    box-shadow: $shadow;
    height: $nav-height;
   
    background-color: $color-white;
    

    @include respond(phone){
        height:$nav-height-phone;
    }

    img{
        height: 6rem;
        width: auto;

        @include respond(phone){
            height: 5.5rem;
            margin-bottom: 1rem;
        }
    }


    &__supercontainer{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 100;
    }

    &__top{
        background-color: $color-primary;
        color:$color-white;
        height: $nav-top;
        display: flex;
        align-items: center;

          @include respond(phone){
                height: $nav-top-phone;
            }

        &--content{
            display: flex;
            justify-content: space-between;

            @include respond(phone){
                flex-direction: column;
            }

            & ul{
                display: flex;
                
                 @include respond(phone){
                    flex-direction: column;
                    align-items: center;
                }

            }
            & li{
                list-style: none;
                position: relative;
                &:not(:last-child){
                    margin-right: 3rem;
                    &::after{
                        content:"\2022";
                        position: absolute;
                        right: -1.5rem;
                        top: 50%;
                        transform: translate(50%, -50%);
                        @include respond(phone){
                            display: none;
                        }
                        
                    }

                    @include respond(phone){
                        margin-right: 0;
                        margin-bottom: 1rem;
                    }
                }
            }
            & a{
                @include buttonNoStyle;
                display: flex;
                align-items: center;
                transition: all .1s;
                &:hover{
                    color:#ccc;
                }
            }
        }
    }
    

    &__container{
        
        display: flex;
        align-items: center;


        @include respond(phone){
            flex-direction: column;
            padding-top: 1.6rem !important;
            padding-bottom: 1.6rem !important;
            align-items: flex-start;
        }
    }

    &__menu{
         @include buttonNoStyle;
        
        height: .4rem;
        display: flex;
        align-items: center;
        height: 100%;
        text-transform: uppercase;
        

        @include respond(phone){
            margin-top: .3rem;
            position: absolute;
            top: 2.7rem + $nav-top-phone;
            right: 1.5rem;
            height: auto;
        }

        &--icon{
            margin-right: .6rem;

            
        }
        &:hover > &--icon >  &--bar{
                &::before{
                    top: -1.2rem;
                }
                &::after{
                    top: 1.2rem;
                }
            }

        &--bar{
            position: relative;
            background-color: $color-primary;
            height: 3px;
            width: 3rem;

             &::before{
                content:"";
                position: absolute;
                left: 0;
                top: -1rem;
                height: 3px;
                width: 100%;
                background-color: currentColor;
                transition: all .2s;
                
            }
            &::after{
                content:"";
                position: absolute;
                left: 0;
                top: 1rem;
                height: 3px;
                width: 100%;
                background-color: currentColor;
                transition: all .2s;
            }
        }

        
    }

    &__infos{
        display: flex;
        align-items: center;
        
        margin-left: 4rem;
        flex:1;
        color:$color-primary;
        height: 100%;

        @include respond(phone){
            flex-direction: column;
            align-items: center;
            margin-left: 0;
            width: 100%;
        }

        &--svg{
            @include svg(2rem);
            margin-right: .6rem;
        }

        a {
            @include buttonNoStyle;
            @include hoverUnderline;

            display: flex;
            align-items: center;
            text-transform: uppercase;

            
        }

        & ul{
            display: flex;
            width: 100%;
            justify-content: space-between;
            @include respond(phone){
                display: none;
            }
        }
        & li{
            list-style: none;
            &:not(:last-child){
                margin-right: 1rem;
            }

        }

        &--item{
            height: 100%;
            &:not(:last-child){
                margin-right: 2rem;
                @include respond(phone){
                    margin-right: 0;
                    margin-bottom: 1rem;
                }
            }

            @include respond(phone){
                font-size: 1.6rem !important;
                }
        }
    }
}

.side{
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;


    & h2{
        text-transform: uppercase;
        letter-spacing: .1;
        margin-bottom: 2.4rem;
        font-size: 3rem;
        letter-spacing: .2rem;

        @include respond(phone){
            margin-bottom: 1rem;
            font-size: 2rem;
        }
    }

    & li{
        
        list-style: none;
        width: 100%;  
        position: relative;
        // &::before{
        //         position: absolute;
        //         content:"";
        //         background-color: $color-white;
        //         width: 1rem;
        //         height: 1rem;
        //         top: 50%;
        //         left: 0;
        //         transform: translateY(-50%);
        //     }

           
    }

    & a{
        @include buttonNoStyle;
        position: relative;
        display: flex;
        padding: 1.2rem 2.4rem;
        width: 100%;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 1.6rem;
        letter-spacing: .2rem;

         &::before{
            color:inherit;
            position: absolute;
            content: "";
            top: 50%;
            left: 0rem;
            transform: translate(0%, -50%);
            height: .6rem;
            border:.6rem solid transparent;
            border-left-color: $color-white;

            // background-color: $color-white;
        }  

        &:hover{
            background-color: $color-white;
            color:$color-primary;
        }

        @include respond(phone){
            font-size: 1.5rem;
            padding: .6rem 1.8rem;
            letter-spacing: 1px;

        }
    }

    &__sub{
        & a{
            justify-content: right;
            padding: .8rem 1.6rem;
            text-transform: none;
            font-weight: 300;
            &::before{
                display: none;

            }
             @include respond(phone){
                font-size: 1.5rem;
                padding: .4rem 1.4rem;
                letter-spacing: 1px;

            }
        }
    }

     &__container{
        position: fixed;
        z-index: 1000;
        top: 0;
        width: 30vw;
        height: 100vh;
        background-color: $color-primary;
        padding: 4rem;
        display: flex;
        flex-direction: column;
        color: $color-white;

        &--hide{
            transition: all .4s;
            right: -100vw;
        }

        &--show{
             transition: all .4s;
            right: 0vw;
        }
         @include respond(tab-land){
            width: 50vw;
            padding: 3rem;
        }
        @include respond(phone){
            width: 100vw;
            padding: 3rem;
        }
        
    }

    &__logo{
        width: 15rem;
        margin: 0 auto;
        & img{
            width: 100%;
            height: auto;
        }
    }

    &__quit{
        @include buttonNoStyle;
        position: absolute;
        top: 2rem;
        right: 2rem;

        transition: all .2s;

        &--svg{
            @include svg(2.4rem);
        }

        &:hover{
            transform: scale(1.1);
        }
    }

    &__contact{
        text-decoration: none;
        color:$color-primary;
        letter-spacing: 0rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 1.2rem;

        padding: .7rem 2rem;
        margin-bottom: 0rem;
        border-radius: 3px;
        background-color: $color-white;
        transition: all .2s;

        &:hover{
            transform: scale(1.05);
        }

        &:not(:last-child){
            margin-bottom: 1rem;
        }

        &--svg{
            @include svg(2rem);
            margin-right: 2rem;
        }
    }
}
</style>