<template>
<div class="page">
    <section-main background="white" titleh1="Le creditoring" subtitle="<strong>La liquidité à la portée  de votre PME</strong>">
          <creditoring></creditoring>
    </section-main>
    <!-- <section-main id="demarche" title="Notre démarche" subtitle="Une démarche <strong>externe</strong>, <strong>structurée</strong> et <strong>indépendante</strong> permettant aux entrepreneurs de se financer rapidement, facilement et durablement aux <strong>meilleures conditions du marché</strong>">
          <router-link :to="{name:'creditoring'}" class="btn btn--blue">
            En savoir plus
      </router-link>
    </section-main> -->
    <section-main id="mission" background="blue" title="Notre démarche" subtitle="Une démarche <strong>externe</strong>, <strong>structurée</strong> et <strong>indépendante</strong> permettant aux entrepreneurs de se financer rapidement, facilement et durablement aux <strong>meilleures conditions du marché</strong>">
          <mission></mission>
    </section-main>
      <section-main id="competences" title="Nos Compétences">
          <competences></competences>   
          
       </section-main>
    <!-- <section-main id="profil" background="blue" title="à Votre écoute">
          <Profil />
    </section-main> -->
    <!-- <section-main background="blue" title="Nos articles" subtitle="Consultez nos différents articles sur le <strong>creditoring</strong> ou <strong>marketing financier</strong>">
          <articles></articles>
    </section-main> -->
    <section-main id="temoignages" background="blue" title="Témoignages" subtitle="Ce que le <strong>creditoring</strong> leur a apporté ?">
          <temoignages></temoignages>
    </section-main>
</div>
</template>

<script>
import { onMounted, watch, defineAsyncComponent } from '@vue/runtime-core'

// @ is an alias to /src
import Construction from '@/components/Construction.vue'
import SectionMain from '@/components/SectionMain.vue'
import Loading from '@/components/utils/Loading';


const Contexte = defineAsyncComponent({
  loader: ()=> import("@/components/Contexte.vue" /* webpackChunkName: "contexte" */),
  loadingComponent: Loading,
  suspensible: false
}); 

const Creditoring = defineAsyncComponent({
  loader: ()=> import("@/components/Creditoring.vue" /* webpackChunkName: "creditoring-home" */),
  loadingComponent: Loading,
  suspensible: false
}); 

const Mission = defineAsyncComponent({
  loader: ()=> import("@/components/Mission.vue" /* webpackChunkName: "mission-home" */),
  loadingComponent: Loading,
  suspensible: false
}); 


const Profil = defineAsyncComponent({
  loader: ()=> import("@/components/Profil.vue" /* webpackChunkName: "profil" */),
  loadingComponent: Loading,
  suspensible: false
}); 


const Temoignages = defineAsyncComponent({
  loader: ()=> import("@/components/Temoignages.vue" /* webpackChunkName: "temoignages" */),
  loadingComponent: Loading,
  suspensible: false
}); 

const Competences = defineAsyncComponent({
  loader: ()=> import("@/components/Competences.vue" /* webpackChunkName: "competences" */),
  loadingComponent: Loading,
  suspensible: false
}); 

import { useRoute } from 'vue-router'

import scrollTo from '@/composables/scroll';




export default {
 components:{
  Construction,
  Competences,
  Contexte,
  SectionMain,
  Creditoring,
  Mission,
  Profil,
  Temoignages
 },
 setup(){
       const route = useRoute();
       
       const pageName = route.name; 



      onMounted(() => {
                  if(route.params.section){
                  scrollTo(route.params.section);
            }
      })
      
      watch( () => route.params, (newVal, oldVal) => {

             if(newVal.section && pageName === route.name){
                  scrollTo(newVal.section);
            }
      })
     
 }
}
</script>

<style lang='scss'>
@import '@/style/main';

.main{
  display: flex;
  flex-direction: column;
  height: 100vh;

  &--grow{
        flex-grow: 1;
  }
}



</style>
