<template>
  <div class="main">
    <Header/>
    <router-view/>
    <Footer/>
  </div>
</template>
<script>

import Header from '@/components/Header.vue'


import Footer from '@/components/Footer.vue'

import {displaySize} from '@/composables/displaySize';

export default  {
 components:{
  Footer,
  Header,
 },
 setup(){
   const screen = displaySize();

   if(screen === 'xlarge'){
     document.getElementById("allpage").style.fontSize = "75%";
   }
 }
}
</script>


<style lang="scss">
@import './style/main';
@import url('https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900&display=swap');

*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
	-webkit-font-smoothing: antialiased;
}


#app {
  font-family: Lato, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $color-text;
  background-color: $color-white;
  font-size: $default-font-size;

  box-sizing: border-box; /*Permet de dimensionner les box en incluant le padding */
	padding: 0rem; /*Mets une bordure au site*/

  ::selection {
    background-color: $color-primary;
    color: $color-white;
  }

}
</style>
